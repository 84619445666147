.App {
	text-align: center;
	padding: 10px;
	background-color: #202020;
	color: #FFF;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

.CompanyInfoWrapper {
	display: flex;
	flex-direction: column;
	text-align: center;
	width: fit-content;
	margin: auto;
	margin-bottom: 30px;
}

.SearchContainer {
	display: flex;
	flex-direction: column;
	gap: 30px;
	justify-content: center;
	align-items: center;
	margin-bottom: 100px;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		-webkit-animation: App-logo-spin infinite 20s linear;
		animation: App-logo-spin infinite 20s linear;
	}
}

.App {
	text-align: center;
	margin: auto;
}

.SectionTitle {
	font-size: 1.1em;
	color: #444A3B;
	text-align: left;
	font-weight: 400;
}

.line {
	width: 100%;
	background: #353535;
	height: 1px;
	border-radius: 7px;
}


.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

.Footer {
	display: flex;
	flex-direction: column;
	margin-top: 300px;
	color: #FFF;
	display: flex;
	flex-direction: column;
}

.FooterLinks {
	display: flex;
	flex-direction: row;
	gap: 20px;
	justify-content: center;
	margin-top: 20px;
}

@-webkit-keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}

.pages {
	max-width: 1280px;
	margin: auto;
	margin-bottom: 200px;
}

.loading {
	display: flex;
	flex-direction: column;
}

.ProjectName {
	font-size: 1.8em;
}

.CompanyName {
	font-size: 0.85em;
	color: #9ba989;
}

.FooterDate {
	font-size: 0.8em;
}

@media (max-width: 600px) {
	.SectionTitle {
		font-size: 0.9em;
	}
}

.Footer{
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	gap: 30px;
}

.FooterContent {
	display: flex;
	flex-direction: column;
	text-align: center;
}

.FooterImage {
	width: 200px;
}

h3 {
	color: #FFF;
}
